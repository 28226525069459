import * as React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { yellow } from '../../design/colors/brand'
import { breakpointNotDesktop} from '../../design/responsive/breakpoint'
const SubNavTitle = styled.div`
    color: ${yellow};
    font-weight: bold;
    padding-right: 4.3rem;
    line-height: 2rem;
    border-right: 2px solid rgba(255,255,255,0.3);
    @media (max-width: ${breakpointNotDesktop}) {
        border: none;
        padding: 0;
    }
`;

const SubNav = styled.nav`
    padding: 1.5rem 0;
    min-height: 8rem;
    display: flex;
    align-items: center;
    .nav-item {
        padding: 0 2rem;
    }
    .nav-link {
        padding: 0.6rem 0;
        color: inherit;
        font-size: 1.6rem;
        font-weight: 400;
        &.active {
            border-bottom: 2px solid #FED70A
        }
        &:hover {
            color: ${yellow}
        }
    }
    .sub-navbar__menu {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .btn {
        white-space: nowrap;
    }
    .btn-mobile {
        display: none
    }
 
    @media (max-width: ${breakpointNotDesktop}) {
        padding:  0  0;
        .btn {
            font-size: 1.2rem;
            padding: 1.05rem 1.5rem;
            border-radius: 1rem;
        }
        .sub-navbar__cta {
           display: none;
        }
        .sub-navbar__left {
            display: block!important;
            width: 100%; 
        }
        .btn-mobile {
            display: inline-block
        } 
        .nav {
           padding: 0 1rem; 
           overflow: auto;
           scroll-snap-type: x mandatory;
           max-width: calc(100% - 1rem);
           flex-wrap: nowrap;
            white-space: nowrap;
        }
        .sub-navbar__name {
            padding: 1rem 0;
            min-height: 60px;
        }
        .sub-navbar__menu { 
            padding: 1rem 0 1rem;
            margin: 0  -1rem 0;
            background: #09418F;
        }
        .nav-link {
            padding: 1rem 0;
            &.active {
                border-bottom: 2px solid ${yellow}
            }
        }
        .nav-item {
            padding:0;
            margin-right: 2rem;
        }
    }
   
`;

const SubMenu = (props: any) => {
    const data = props.data || []
    const subMenu = data.sub_menu.links || []
    const cta = data.sub_menu.sub_menu_cta || null
    const title = data.sub_menu.title || null
    const ctaLink = cta?.link || null
    const ctaText = cta?.text ||  null
  return (
    <>
        <SubNav className='sub-navbar bg-blue' >
            <div className='container'>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='sub-navbar__left d-lg-flex align-items-center pr-5'>
                        <div className='sub-navbar__name d-flex justify-content-between align-items-center'>
                            <SubNavTitle>{title}</SubNavTitle>
                            {
                                cta ? (
                                    <Link to={ctaLink} className='btn btn-outline-white  btn-mobile'>
                                            {
                                                ctaText
                                            }
                                        </Link>
                                ) : null
                                
                            }
                        </div>
                        {
                            subMenu.length ? (
                                <div className='sub-navbar__menu'>
                                    <ul className='nav'>
                                        {
                                            subMenu.map((item: any)=>(
                                            
                                                <li className='nav-item' key={item.id}>
                                                    <Link to={item.link} className={(item.active === 'true') ? 'nav-link active' : ' nav-link'}>{item.text}</Link>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            ) : null
                        }
                    </div>
                    {
                        cta ? (
                            <div className='sub-navbar__cta'>
                                <Link to={ctaLink} className='btn btn-outline-white'>
                                    {
                                        ctaText
                                    }
                                </Link>
                            </div>
                        ) : null
                        
                    }
                </div>
            </div>   
        </SubNav>

    </>
  )
}
export default SubMenu
