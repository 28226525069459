import * as React from "react"
import Layout from "../components/common/layout"
import {  graphql } from "gatsby"
import { Helmet } from 'react-helmet'
import ListPosts from "../components/list-posts"
import SubMenu from "../components/common/sub-menu"
import Heading from "../components/common/heading"
import Hero from "../components/hero"
// markup 
const Blog = ({ data }: any) => {
  const _data = data.allSampleDataJson?.nodes[0].data || []
  const subMenu = _data.find((item: any) => item.block === 'sub-menu')
  const posts = _data.find((item: any) => item.block === 'post')
  const hero = _data.find((item: any) => item.block === 'hero')
  const link = {
    "link": "/blog",
    "text": "View all",
  }
  const featurePost  = {
    "id": 1,
    "title": "For a Longer Life and Happier Gut, Eat More Fiber",
    "image" : "/images/article-1-0.jpg",
    "link": "/post-detail",
    "content": "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt…. "
}
  return (
    <Layout>
      <Helmet
        title='Nutrition'
      />
        <SubMenu data={subMenu.data[0]} />
        <Hero container={true} background="bg-blue-white"  data={hero.data[0]} noSlider={true}></Hero>
        <Heading 
            link={link}
            title='Sub Category' 
            background='bg-white'  
        />
        <ListPosts data={posts.data[1]} />
        <Heading 
            link={link}
            title='Sub Category' 
            background='bg-white'  
        />
        <ListPosts data={posts.data[1]} />
        <Heading 
            link={link}
            title='Sub Category' 
            background='bg-white'  
        />
        <ListPosts data={posts.data[1]} />
        <Heading 
            link={link}
            title='Sub Category' 
            background='bg-white'  
        />
        <ListPosts data={posts.data[1]} />
    </Layout>
  )
}

// Step 2: Export a page query
export const query = graphql`
  query {
    allSampleDataJson(filter: {jsonName: {in: ["blog-category-sub"]}}) {
      nodes {
        jsonName
        data {
          block
          data {
            hero {
              hight_light
              content
              images {
                id
                url
                width
              }
              title
              title_top
            }
            icons {
              icon_link {
                id
                title
                image
                link
              }
            }
            sub_menu{
              title
              links {
                id
                text
                link
                active
              }
            }
            cards {
              id
              title
              image
              link
            }
            posts {
              id
              title
              link
              content
              image
            }
          }
        }
      }
    }
  }
`
export default Blog
