import * as React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { borderRadius, borderRadiusSm} from '../../design/spacing/margin'
import { breakpointMd} from '../../design/responsive/breakpoint'
import CardImage from './card-image'
const ArticleContent = styled.article`
  margin-bottom: 2rem;
  display: block;
  
  .article-image {
    flex: 0 0 19rem;
    max-width: 19rem;
  
  }
  .ratio {
    border-radius: ${borderRadiusSm};
  }
  .flex-column {
    .article-image {
      flex: 1;
      margin-bottom: 2.5rem;
      max-width: 100%;
    }
    .article-body {
      padding-left: 0;
    }
    .ratio {
      border-radius: ${borderRadius};
    }
    h4 {
      font-size: 3.6rem;
      margin-bottom: 3.5rem;
    }
    .article-text {
      font-size: 2rem;
    }
  }
  @media (max-width: ${breakpointMd}) {
    margin-bottom: 3.5rem;
    &:last-child {
      margin-bottom:0;
    }
    .flex-column {
      margin-bottom: 3.5rem;
    }
    .flex-column {
      .ratio {
        border-radius: 1.4rem;
      }
    }
    .ratio {
      border-radius: 3.2rem;
    }
  }
`
const Content = styled.article`
 
  @media (min-width: ${breakpointMd}) {
    padding-left: 2rem;
  }
`

const Title = styled.h4``

const Text = styled.div`
  font-size: 1.6rem
`
  
const SearchLocation = (props: any) => {
  const data = props.data;
  const image = data.image || null
  const title = data.title || null
  const content = data.content || null
  const link = data.link || null
  const vertical = props.vertical  ? 'd-md-flex flex-column': 'd-md-flex align-items-start'
  return (
    <>
       <ArticleContent className={'article '}>
          <div className={vertical}>
            <Link className='article-image' to={link}>
              <CardImage  src={image} alt='' ratio='3x2' />
            </Link>
            
            <Content className='article-body mt-5 mt-md-0'>
              <Title className='mb-3'>
                <Link className='text-decoration-none' to={link}>{title}</Link>
              </Title>
              <Text className='article-text'>{content}</Text>
            </Content>
          </div>
       </ArticleContent>
    </>
  )
}

export default SearchLocation
