import * as React from 'react'
import Post from './common/post'
import Block from './common/block'
import Slider from 'react-slick';
const Posts = (props: any) => {
  const posts = props.data.posts;
  const vertical = props.vertical ? 'col-lg-12': 'col-lg-6';
  const settings = {
      dots: false,
      infinite: true,
      slidesToShow: 2,
      slidesToScroll: 2,
      arrows: false,
      autoplay: false,
      slidesPerRow: 2,
      responsive: [
        {
          breakpoint: 992,
          settings: {
              variableWidth: true,
              slidesToShow: 1,
              slidesPerRow: 1,
              slidesToScroll: 1
          }
        }
      ]
};
  return (
    <>
      <Block className='list-post'>
        <div className='container'>
          {
            posts.length ? (
              <Slider {...settings} className='d-block d-lg-none'>
                {
                  posts.map((post: any)=>(
                    <Post data={post} />
                  ))
                }
            </Slider>
            ) : null
          }
          {
            posts.length ? (
              <div className='row d-none d-lg-flex'>
                {
                  posts.map((post: any)=>(
                      <div className={vertical} key={post.id}>
                            <Post data={post} /> 
                      </div>
                  ))
                }
            </div>
            ) : null
          }

          
          
        </div>
      </Block>
      
    </>
  )
}

export default Posts
